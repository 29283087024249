// @ts-nocheck
import React, { Suspense } from 'react'
import fetcher from '../fetcher'
import scrollTo from '../smoothScrollTo'

function isMobileSafari() {
	return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
}
function formToJSON(elem) {
	let object = {}

	let formData = new FormData(elem)

	formData.forEach((value, key) => {
		if (key.includes('[]')) {
			let relKey = key.split('[]')[0]
			if (!object[relKey]) {
				object[relKey] = []
			}

			object[relKey].push(value)
		} else {
			object[key] = value
		}
	})

	return object
}

class FormInput {
	element = null

	input = null

	touched = false

	constructor({ element }) {
		this.element = element
		this.input = this.element.querySelector(
			`input[type=text], input[type=email], input[type=password], input[type=checkbox].form-control, input[type=number], input[type=file], .controls-area, input[type=hidden], textarea, select, .tree, .spoilers`
		)

		this.input.addEventListener('change', () => {
			this.updateTouched()
		})

		this.input.addEventListener('keyup', () => {
			this.updateTouched()
		})

		this.updateTouched()
	}

	checkInputExists() {
		return !!this.input
	}

	getName() {
		return this.checkInputExists() ? this.input.getAttribute(`name`) : ``
	}

	updateTouched() {
		this.touched = this.input.value.length > 0
		if (this.touched) {
			this.element.classList.add('touched')
		} else {
			this.element.classList.remove('touched')
		}
	}

	getElement() {
		return this.element
	}

	clearError() {
		this.element.classList.remove(`is-invalid`)
		;[...this.element.querySelectorAll(`.typography--error`)].map(e => {
			e.remove()
		})
	}

	setError(messages) {
		this.clearError()
		this.element.classList.add(`is-invalid`)
		let new_error = document.createElement('div')
		new_error.classList.add(`typography`)
		new_error.classList.add(`typography--error`)
		new_error.innerText = messages.join(`\n`)
		this.element.append(new_error)
	}
}

class AjaxForm {
	formElement = null

	formInputs = {}

	formSubmitActors = []

	validated = true

	sending = false

	constructor({ form }) {
		this.formElement = form
		this.bindForm()
	}

	checkBtns() {
		this.formSubmitActors.map(element => {
			if (this.validated && !this.sending) {
				element.disabled = false
				element.classList.remove('disabled')
			} else {
				element.disabled = true
				element.classList.add('disabled')
			}
		})
	}

	checkValidate() {
		if (this.formInputs?.accept) {
			let element = this.formInputs.accept.input
			this.validated = element.checked
			this.checkBtns()
		}
	}

	clearErrors() {
		for (const [key, formInput] of Object.entries(this.formInputs)) {
			formInput.clearError()
		}
	}

	setErrors(errors) {
		let index = 0

		for (const [key, value] of Object.entries(errors)) {
			if (this.formInputs[key]) {
				this.formInputs[key].setError(value)

				if (index == 0) {
					scrollTo({
						element: this.formInputs[key].getElement(),
					})
				}
			}

			if (this.formInputs[`${key}[]`]) {
				this.formInputs[`${key}[]`].setError(value)

				if (index == 0) {
					scrollTo({
						element: this.formInputs[key].getElement(),
					})
				}
			}

			index++
		}
	}

	async getActionUrl() {
		return this.formElement.hasAttribute(`data-action`)
			? this.formElement.getAttribute(`data-action`)
			: this.formElement.getAttribute(`action`)
	}

	async getActionMethod() {
		return (
			this.formElement.hasAttribute(`data-method`)
				? this.formElement.getAttribute(`data-method`)
				: this.formElement.getAttribute(`method`)
		).toLocaleUpperCase()
	}

	async sendForm() {
		this.sending = true
		this.checkBtns()
		try {
			let url = await this.getActionUrl()
			let method = await this.getActionMethod()

			let response = await fetcher(url, {
				method,
				body: formToJSON(this.formElement),
			})

			let data = await response.json()

			if (data.errors) {
				this.clearErrors()

				if (data.message) {
					window.makeModalAuth({
						message:
							data.message == `The given data was invalid.`
								? `В заполнении формы есть ошибки, пожалуйста исправьте их.`
								: data.message,
						type: 'error',
					})
				}

				this.setErrors(data.errors)
				this.sending = false
				this.checkBtns()
			} else {
				this.clearErrors()

				if (data.exception) {
					makeSnack('error', data.message)
				}

				if (data.message && !data.exception) {
					window.makeModalAuth({
						message: data.message,
						type: 'success',
					})
				}

				if (data.flash) {
					makeSnack('success', data.flash)
				}

				if (data.redirect) {
					window.location.href = data.redirect
				}

				if (data.snack && data.snackType) {
					makeSnack(data.snackType, data.snack)
				}

				if (data.closeForm && data.formMessage) {
					this.formElement.innerHTML = `<p class="typography">${data.formMessage}</p>`
				}

				if (data.messageRich) {
					window.makeMessageModal({
						icon: false,
						closeBtn: false,
						type: 'slim',
						allowOverflow: false,
						html: `
						<div class="typography typography--left">
							<h2 style="font-size: 24px;line-height: 36px; margin: 0; margin-bottom: 8px;">${data.messageRich.title}</h2>
							<p style="font-size: 20px; line-height: 32px; margin-bottom: 32px;">${data.messageRich.description}</p>
							<div class="btn-group btn-group--centered mb-0"><div class="btn btn-action btn--small btn--block mb-0" data-close-modal>${data.messageRich.button}</div></div>
						</div>
						
						`,
					})
				}
				this.sending = false
				this.checkBtns()
			}

			console.log(`form succ`, data)
		} catch (e) {
			console.log(`form catch`, e)
			this.sending = false
			this.checkBtns()
		}
	}

	bindForm() {
		// [...this.formElement.querySelectorAll(`input[type=text], input[type=email], input[type=password], input[type=number], textarea`)].map(element=>{

		;[...this.formElement.querySelectorAll(`.form-input`)].map(element => {
			let form_input = new FormInput({
				element,
			})

			if (form_input.checkInputExists()) {
				if (form_input.input.type === 'checkbox') {
					form_input.input.addEventListener('change', () => {
						this.checkValidate()
					})
				} else {
					form_input.input.addEventListener('keyup', () => {
						this.checkValidate()
					})
				}
			}

			this.formInputs[form_input.getName()] = form_input
		})

		if (this.formElement.querySelector('.disabled-temporary')) {
			let disabled = true
			;[...this.formElement.querySelectorAll(`.form-input`)].map(element => {
				const checkFormAvaliability = () => {
					disabled = !isMobileSafari()
					;[...this.formElement.querySelectorAll(`.form-input input`)].map(element2 => {
						if (element2.value.length > 0) {
							disabled = false
						}
					})

					if (disabled) {
						this.formElement.querySelector('.disabled-temporary').classList.add('disabled')
					} else {
						this.formElement.querySelector('.disabled-temporary').classList.remove('disabled')
					}
				}
				try {
					element.addEventListener('keyup', checkFormAvaliability)
					element.addEventListener('keydown', checkFormAvaliability)
					element.addEventListener('paste', checkFormAvaliability)
					element.addEventListener('copy', checkFormAvaliability)
					element.addEventListener('cut', checkFormAvaliability)
					element.addEventListener('change', checkFormAvaliability)
				} catch (e) {}

				if (isMobileSafari()) {
					checkFormAvaliability()
				}
			})
		}

		this.formSubmitActors = [...this.formElement.querySelectorAll('button')]

		this.formElement.addEventListener('submit', event => {
			// Prevent form from submitting to the server
			event.preventDefault()

			if (this.validated) {
				this.sendForm()
			}
		})

		this.checkValidate()
	}
}

export { AjaxForm }
